import {
  FilterList as FilterListIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { Button, IconButton, Menu } from '@mui/material';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';
import { filter, isEmpty, map, omitBy, transform } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ShopSelect,
  UserSelect,
  FormikDatePicker,
  OrderStatusSelect,
  FormikSelect,
  TraderSelect,
  OperatorSelect,
  OrderAutomationStatusSelect,
  FormikDateTimePicker,
  FormikNumericField,
  FormikMultiSelect,
} from 'components';
import { FilterDefinitionType } from 'enums';
import { TranslationNamespace } from 'i18n';
import { CommonDefinitionSelect, FilterDefinition } from 'types';
import { validationUtils } from 'utils';

type Props = {
  definitions: FilterDefinition[];
  initialValues?: any;
  filters?: any;
  onReset: () => void;
  onSubmit: (values: any) => void;
};

export type FiltersDropdownProps = Props;

export const FiltersDropdown: React.FC<Props> = ({
  definitions,
  filters,
  initialValues: initialValuesProp,
  onReset,
  onSubmit,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.filters_dropdown',
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const getDefinitionDefaultValue = useCallback(
    (definition: FilterDefinition) =>
      // add conditions based on definitionType if needed (e.g. for number or date)
      {
        if (definition.type === FilterDefinitionType.MultiSelect) {
          return [];
        }

        return '';
      },
    [],
  );

  const visibleDefinitions = useMemo(
    () => filter(definitions, (definition) => !definition.hidden),
    [definitions],
  );

  const initialValues = useMemo(
    () =>
      transform(
        visibleDefinitions,
        (values: Record<string, any>, definition) => {
          values[definition.name] =
            initialValuesProp?.[definition.name] ||
            getDefinitionDefaultValue(definition);
        },
        {},
      ),
    [visibleDefinitions, initialValuesProp, getDefinitionDefaultValue],
  );

  const open = useMemo(() => !!anchorEl, [anchorEl]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleReset = useCallback(() => {
    onReset();
    handleClose();
  }, [handleClose, onReset]);

  const handleSubmit = useCallback(
    (values: any, helpers: FormikHelpers<any>) => {
      helpers.setSubmitting(false);
      onSubmit(values);
      handleClose();
    },
    [handleClose, onSubmit],
  );

  const filtersDirty = useMemo(
    () => !isEmpty(omitBy(filters, isEmpty)),
    [filters],
  );

  const autocompleteEnabled = useCallback(
    (filterDefinition: CommonDefinitionSelect) =>
      // true as default for undefined to handle existing filters too
      filterDefinition?.autocomplete !== false,
    [],
  );

  return (
    <div>
      <IconButton onClick={handleClick}>
        <FilterListIcon color={filtersDirty ? 'info' : 'inherit'} />
      </IconButton>
      <Menu
        PopoverClasses={{
          paper: 'tw-border tw-px-4 tw-py-2',
        }}
        MenuListProps={{
          sx: {
            width: 240,
            padding: 0,
          },
        }}
        variant="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div>
          <div className="tw-flex tw-items-center">
            <div className="tw-text-lg tw-font-bold tw-flex-1">
              {t('title')}
            </div>
            <CloseIcon className="tw-cursor-pointer" onClick={handleClose} />
          </div>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="tw-grid tw-gap-2 tw-mt-4 tw-mb-8">
                {map(visibleDefinitions, (filterDefinition) => {
                  if (filterDefinition.type === FilterDefinitionType.Date) {
                    return (
                      <FormikDatePicker
                        key={filterDefinition.name}
                        label={filterDefinition.label}
                        name={filterDefinition.name}
                        startOf={filterDefinition.startOf}
                        endOf={filterDefinition.endOf}
                        minDate={filterDefinition.minDate}
                        maxDate={filterDefinition.maxDate}
                      />
                    );
                  } else if (
                    filterDefinition.type === FilterDefinitionType.DateTime
                  ) {
                    return (
                      <FormikDateTimePicker
                        key={filterDefinition.name}
                        label={filterDefinition.label}
                        name={filterDefinition.name}
                        startOf={filterDefinition.startOf}
                        endOf={filterDefinition.endOf}
                        minDate={filterDefinition.minDate}
                        maxDate={filterDefinition.maxDate}
                      />
                    );
                  } else if (
                    filterDefinition.type === FilterDefinitionType.Text
                  ) {
                    return (
                      <Field
                        key={filterDefinition.name}
                        component={TextField}
                        label={filterDefinition.label}
                        type="text"
                        variant="standard"
                        name={filterDefinition.name}
                        validate={
                          filterDefinition.format &&
                          validationUtils.validators[filterDefinition.format]
                        }
                      />
                    );
                  } else if (
                    filterDefinition.type === FilterDefinitionType.Numeric
                  ) {
                    return (
                      <FormikNumericField
                        key={filterDefinition.name}
                        label={filterDefinition.label}
                        allowNegative={false}
                        variant="standard"
                        name={filterDefinition.name}
                      />
                    );
                  } else if (
                    filterDefinition.type === FilterDefinitionType.Enum
                  ) {
                    return (
                      <FormikSelect
                        key={filterDefinition.name}
                        label={filterDefinition.label}
                        name={filterDefinition.name}
                        noneOption
                        variant="standard"
                        autocomplete={autocompleteEnabled(filterDefinition)}
                        options={map(
                          filterDefinition.enum,
                          (value: string) => ({
                            value,
                            label:
                              filterDefinition.getDisplayName?.(value) || value,
                          }),
                        )}
                      />
                    );
                  } else if (
                    filterDefinition.type === FilterDefinitionType.Select
                  ) {
                    return (
                      <FormikSelect
                        key={filterDefinition.name}
                        label={filterDefinition.label}
                        name={filterDefinition.name}
                        noneOption
                        variant="standard"
                        options={filterDefinition.options}
                        autocomplete={autocompleteEnabled(filterDefinition)}
                      />
                    );
                  } else if (
                    filterDefinition.type === FilterDefinitionType.MultiSelect
                  ) {
                    return (
                      <FormikMultiSelect
                        key={filterDefinition.name}
                        label={filterDefinition.label}
                        name={filterDefinition.name}
                        variant="standard"
                        options={filterDefinition.options}
                      />
                    );
                  } else if (
                    filterDefinition.type === FilterDefinitionType.User
                  ) {
                    return (
                      <UserSelect
                        key={filterDefinition.name}
                        name={filterDefinition.name}
                        label={filterDefinition.label}
                        users={filterDefinition.users}
                        autocomplete={autocompleteEnabled(filterDefinition)}
                        variant="standard"
                      />
                    );
                  } else if (
                    filterDefinition.type === FilterDefinitionType.Shop
                  ) {
                    return (
                      <ShopSelect
                        key={filterDefinition.name}
                        name={filterDefinition.name}
                        label={filterDefinition.label}
                        shops={filterDefinition.shops}
                        autocomplete={autocompleteEnabled(filterDefinition)}
                        variant="standard"
                      />
                    );
                  } else if (
                    filterDefinition.type === FilterDefinitionType.OrderStatus
                  ) {
                    return (
                      <OrderStatusSelect
                        key={filterDefinition.name}
                        name={filterDefinition.name}
                        label={filterDefinition.label}
                        statuses={filterDefinition.orderStatuses}
                        autocomplete={autocompleteEnabled(filterDefinition)}
                        variant="standard"
                      />
                    );
                  } else if (
                    filterDefinition.type === FilterDefinitionType.Trader
                  ) {
                    return (
                      <TraderSelect
                        key={filterDefinition.name}
                        name={filterDefinition.name}
                        label={filterDefinition.label}
                        traders={filterDefinition.traders}
                        autocomplete={autocompleteEnabled(filterDefinition)}
                        variant="standard"
                      />
                    );
                  } else if (
                    filterDefinition.type === FilterDefinitionType.Operator
                  ) {
                    return (
                      <OperatorSelect
                        key={filterDefinition.name}
                        name={filterDefinition.name}
                        label={filterDefinition.label}
                        operators={filterDefinition.operators}
                        autocomplete={autocompleteEnabled(filterDefinition)}
                        variant="standard"
                      />
                    );
                  } else if (
                    filterDefinition.type ===
                    FilterDefinitionType.OrderAutomationStatus
                  ) {
                    return (
                      <OrderAutomationStatusSelect
                        key={filterDefinition.name}
                        name={filterDefinition.name}
                        label={filterDefinition.label}
                        statuses={filterDefinition.orderAutomationStatuses}
                        autocomplete={autocompleteEnabled(filterDefinition)}
                        variant="standard"
                      />
                    );
                  }
                  return null;
                })}
              </div>
              <div className="tw-grid tw-grid-cols-2 tw-gap-2">
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={handleReset}
                >
                  {t('buttons.reset')}
                </Button>
                <Button
                  variant="outlined"
                  color="info"
                  size="small"
                  type="submit"
                >
                  {t('buttons.submit')}
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      </Menu>
    </div>
  );
};
