import { filter, intersection, map } from 'lodash';
import React, { useMemo } from 'react';

import { FormikSelect, FormikSelectProps } from 'components';
import { useUserContext } from 'hooks';
import { formUtils } from 'utils';

type Props = {
  name: string;
  label: string;
  assetCurrencyId?: string;
  exclude?: string[];
  tradeMethodsIds?: string[];
} & Partial<FormikSelectProps>;

export const P2PProviderSelect: React.FC<Props> = ({
  name,
  label,
  assetCurrencyId,
  exclude = [],
  tradeMethodsIds = [],
  ...rest
}) => {
  const { p2pProviders } = useUserContext();
  const availableP2PProviders = useMemo(
    () =>
      filter(p2pProviders, (p2pProvider) => {
        const p2pProviderTradeMethodsId = map(
          p2pProvider?.payinConfig?.tradeMethods,
          ({ id }) => id,
        );

        if (!intersection(tradeMethodsIds, p2pProviderTradeMethodsId).length) {
          return false;
        }

        if (
          assetCurrencyId &&
          assetCurrencyId !== p2pProvider.assetCurrencyId
        ) {
          return false;
        }

        if (exclude?.includes(p2pProvider.id)) {
          return false;
        }

        return true;
      }),
    [p2pProviders, tradeMethodsIds, assetCurrencyId, exclude],
  );

  const providersOptions = useMemo(
    () => formUtils.getOptions(availableP2PProviders),
    [availableP2PProviders],
  );

  return (
    <FormikSelect
      noneOption
      label={label}
      {...rest}
      name={name}
      options={providersOptions}
    />
  );
};
