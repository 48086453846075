import { Autocomplete, MenuItem, TextFieldProps } from '@mui/material';
import { Field, useField } from 'formik';
import { TextField } from 'formik-mui';
import { every, filter, find, isString, map, sortBy } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n';

export type FormikSelectOption = {
  label: React.ReactNode | string;
  value: string;
  disabled?: boolean;
};

type Props = {
  label: React.ReactNode | string;
  name: string;
  options: FormikSelectOption[];
  noneOption?: boolean;
  showMetaError?: boolean;
  autocomplete?: boolean;
} & Partial<TextFieldProps>;

export type { Props as FormikSelectProps };

export const FormikSelect: React.FC<Props> = ({
  label,
  name,
  options,
  noneOption,
  showMetaError,
  autocomplete = false,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.formik_select',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const [field, meta, helpers] = useField(name);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const isSelectedDisabled = useMemo(
    () => find(options, { value: field.value })?.disabled,
    [field.value, options],
  );

  const noneOptionConfig = useMemo(
    () => ({
      value: '',
      label: t('none_option'),
    }),
    [t],
  );

  const asAutocomplete = useMemo(
    () => autocomplete && every(options, (option) => isString(option.label)),
    [autocomplete, options],
  );

  const filteredOptions = useMemo(() => {
    let result = options;
    if (autocomplete && inputValue) {
      result = filter(options, (option) =>
        (isString(option.label) ? option.label : '')
          .toLowerCase()
          .includes(inputValue.toLowerCase()),
      );
    }
    if (noneOption && asAutocomplete) {
      result = [noneOptionConfig, ...result];
    }
    return result;
  }, [
    options,
    autocomplete,
    inputValue,
    noneOption,
    asAutocomplete,
    noneOptionConfig,
  ]);

  if (asAutocomplete) {
    return (
      <Autocomplete
        options={filteredOptions}
        getOptionLabel={(option) =>
          option.value === '' ? noneOptionConfig.label : String(option.label)
        }
        renderInput={(params) => (
          <Field
            {...params}
            {...rest}
            component={TextField}
            name={name}
            label={label}
            size="small"
            inputRef={inputRef}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error ? meta.error : ''}
          />
        )}
        value={
          find(filteredOptions, { value: field.value }) ||
          (null as any as FormikSelectOption)
        }
        inputValue={field.value === '' ? '' : inputValue}
        disableClearable
        fullWidth
        isOptionEqualToValue={(option, value) => option.value === value?.value}
        onChange={(_, newValue, reason) => {
          if (newValue && newValue.value === '') {
            helpers.setValue('');
            setInputValue('');
          } else {
            helpers.setValue(newValue ? newValue.value : '');
            setInputValue(newValue ? String(newValue.label) : '');
          }

          if (inputRef.current) {
            inputRef.current.blur();
          }
        }}
        onInputChange={(_, newInputValue) => {
          if (field.value !== '') {
            setInputValue(newInputValue);
          }
        }}
      />
    );
  }

  return (
    <Field
      {...rest}
      component={TextField}
      name={name}
      label={label}
      select
      size="small"
      {...(isSelectedDisabled && {
        error: true,
        helperText: tCommon('errors.invalid'),
      })}
      {...(showMetaError &&
        meta.error && {
          error: true,
          helperText: meta.error,
        })}
    >
      {noneOption && (
        <MenuItem value={noneOptionConfig.value}>
          {noneOptionConfig.label}
        </MenuItem>
      )}
      {map(sortBy(filteredOptions, 'disabled'), (option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={option.disabled}
        >
          {option.label}
        </MenuItem>
      ))}
    </Field>
  );
};
